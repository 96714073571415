import React, { useState } from "react";

const Alphabet = ({ handleClick, disabled }) => {
  const [selectedLetter, setSelectedLetter] = useState("");

  const onClick = (letter) => {
    setSelectedLetter(letter);
    handleClick(letter);
  };

  return (
    <div
      className="mb-4"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 16,
      }}
    >
      <div>
        {"abcdefghijkl".split("").map((letter) => (
          <button
            className="border"
            key={letter}
            disabled={disabled}
            onClick={() => onClick(letter)}
            style={{
              backgroundColor: letter === selectedLetter ? "yellow" : "white",
              width: 30,
            }}
          >
            {letter}
          </button>
        ))}
      </div>
      <br />
      <div>
        {"mnopqrst".split("").map((letter) => (
          <button
            className="border"
            key={letter}
            disabled={disabled}
            onClick={() => onClick(letter)}
            style={{
              backgroundColor: letter === selectedLetter ? "yellow" : "white",
              width: 30,
            }}
          >
            {letter}
          </button>
        ))}
      </div>
      <br />
      <div>
        {"uvwxyz".split("").map((letter) => (
          <button
            className="border"
            key={letter}
            disabled={disabled}
            onClick={() => onClick(letter)}
            style={{
              backgroundColor: letter === selectedLetter ? "yellow" : "white",
              width: 30,
            }}
          >
            {letter}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Alphabet;
