export const wordsMap = [
  {
    serialNum: 0,
    word: "java",
    hint: "Language developed at Sun Microsystems in mid-1990s",
  },
  {
    serialNum: 1,
    word: "css",
    hint: "Language that controls the appearance of a website",
  },
  {
    serialNum: 2,
    word: "react",
    hint: "Library developed by Facebook",
  },
  {
    serialNum: 3,
    word: "code",
    hint: "Thing you do apart from eat and sleep",
  },
  {
    serialNum: 4,
    word: "saas",
    hint: "Apps available to customers over the internet",
  },
  {
    serialNum: 5,
    word: "html",
    hint: "Language developed by Tim Berners-Lee for creating website",
  },
  {
    serialNum: 6,
    word: "data",
    hint: "Digital information",
  },
  {
    serialNum: 7,
    word: "yarn",
    hint: "Faster and reliable package manager than NPM",
  },
  {
    serialNum: 8,
    word: "npm",
    hint: "This allows to easily install and update packages",
  },
  {
    serialNum: 9,
    word: "byte",
    hint: "Represents single character in memory",
  },
  {
    serialNum: 10,
    word: "node",
    hint: "Tech built on Chrome's V8 JavaScript engine",
  },
  {
    serialNum: 11,
    word: "api",
    hint: "Protocols that allow apps to communicate with each other",
  },
  {
    serialNum: 12,
    word: "rest",
    hint: "Architecture used to build web APIs",
  },
  {
    serialNum: 13,
    word: "git",
    hint: "Helps developer manage and track changes to their code",
  },
];

export function getSkippedRandomNumber(arr = []) {
  let num = Math.floor(Math.random() * wordsMap.length);

  if (arr.length >= wordsMap.length) {
    return 0;
  }

  if (arr.includes(num)) {
    // If the number is in the array, call the function again to generate a new number
    return getSkippedRandomNumber(arr);
  } else {
    // If the number is not in the array, return it
    return num;
  }
}
