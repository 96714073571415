import React from "react";
import Footer from "../home/footer/Footer";
import Navbar from "../home/navbar/Navbar";
import Apps from "./Apps";
import AppsHead from "./AppsHead";
import SEO from "../Seo";
import * as styles from "./apps.module.css";

const Index = () => {
  return (
    <div className={styles.componentWrapper}>
      <Navbar />
      <SEO
        titleStub={`Apps`}
        urlStub={`/apps/`}
        description={`Puzzle game for tech enthusiasts
        `}
        image={"https://i.imgur.com/EwC5Y2Y.webp"}
      />
      {/* <AppsHead /> */}
      <Apps />
      <Footer />
    </div>
  );
};

export default Index;
