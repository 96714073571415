import React, { useState, useEffect } from "react";
import Alphabet from "./Alphabet";
import SocialShare from "./../../SocialShare";
import { wordsMap } from "./utils";

const Wordle = ({ wordMap = {}, resetGame, gameComplete }) => {
  const [won, setWon] = useState(false);
  const [lost, setLost] = useState(false);
  const [score, setScore] = useState(0);

  const [letters, setLetters] = useState([]);
  const [guesses, setGuesses] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedLetter, setSelectedLetter] = useState("");

  useEffect(() => {
    if (!wordMap.word) {
      return;
    }

    if (wordMap.word.split("").every((c) => letters.includes(c))) {
      setWon(true);
      setScore(score + 1);
      return;
    }

    if (guesses.length > wordMap.word.length) {
      setLost(true);
    }
  }, [wordMap.word, letters, guesses]);

  const handleClick = (letter) => {
    setSelectedLetter(letter);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedLetter === "") {
      setMessage("Please select a letter.");
      return;
    }
    if (guesses.includes(selectedLetter)) {
      setMessage("You already guessed that letter!");
    } else if (wordMap.word.includes(selectedLetter)) {
      setLetters(
        wordMap.word
          .split("")
          .map((c) =>
            c === selectedLetter ? c : letters[wordMap.word.indexOf(c)]
          )
      );
      setGuesses((prevGuesses) => [...prevGuesses, selectedLetter]);
      setMessage("");
    } else {
      setGuesses((prevGuesses) => [...prevGuesses, selectedLetter]);
      setMessage("");
    }
  };

  const onReset = () => {
    setLetters([]);
    setGuesses([]);
    setMessage("");
    setWon(false);
    setLost(false);
    resetGame();
  };

  const getSocialShareTitle = () => {
    return `I scored ${score}/${wordsMap.length} on #CrossWordle. Can you beat me? `;
  };

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-3xl pb-2">CrossWordle</h1>
      <h3 className="text-sm italic pb-8">Puzzle game for tech enthusiasts</h3>
      <h2 className="border border-2 text-2xl p-2">
        Sore: {score}/{wordsMap.length}
      </h2>
      {!gameComplete && (
        <>
          <h2 className="text-xl py-8">
            💡 Q {wordMap.serialNum + 1}: {wordMap.hint}
          </h2>
          <p className="pb-2">
            Guesses remaining:{" "}
            <span className="text-red-500 text-2xl px-2">
              {wordMap.word?.length - guesses.length + 1}
            </span>
          </p>
          <>
            <p className="pb-2">{message}</p>
            <p className="text-3xl">
              {wordMap.word
                ?.split("")
                .map((c) => (letters.includes(c) ? c : "_"))
                .join(" ")}
            </p>
            <Alphabet disabled={won || lost} handleClick={handleClick} />
            <button
              style={{ marginTop: "16px" }}
              disabled={won || lost}
              onClick={handleSubmit}
              className="p-2 w-200 mt-8 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded"
            >
              Guess
            </button>
            <button
              style={{ marginTop: "16px" }}
              disabled={wordMap.serialNum >= wordsMap.length}
              onClick={onReset}
              className="w-200 mt-8 text-red-500 hover:text-red-700 background-transparent px-4"
            >
              Next
            </button>
          </>
        </>
      )}
      <div className="flex flex-col items-center py-8">
        {gameComplete && (
          <>
            <p className="pb-2">~End~</p>
            <p className="pb-2">Challenge your friends 👇</p>
          </>
        )}
        <SocialShare getSocialShareTitle={getSocialShareTitle}></SocialShare>
      </div>
    </div>
  );
};

export default Wordle;
