import React, { useState } from "react";
import Wordle from "./Wordle";
import { wordsMap } from "./utils";

let questionNum = 0;

const App = () => {
  const [wordMap, setWordMap] = useState(wordsMap[questionNum]);
  const [gameComplete, setGameComplete] = useState(false);

  const resetGame = () => {
    questionNum++;
    if (questionNum < wordsMap.length) {
      setWordMap(wordsMap[questionNum]);
    } else {
      setGameComplete(true);
    }
  };

  if (!wordMap) {
    return null;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: 120 }}>
      <Wordle
        wordMap={wordMap}
        resetGame={resetGame}
        gameComplete={gameComplete}
      />
    </div>
  );
};

export default App;
