import React from "react";
import Ground from "./Ground/Ground";
import WordleApp from "./wordle/App";
import * as styles from "./apps.module.css";

const Apps = () => {
  return (
    <div className={styles.wrapper}>
      <div className="grid grid-cols-1 divide-y">
        {/* <Ground /> */}
        <WordleApp />
        <div className="flex flex-col items-center justify-center py-16 text-sm text-center">
          <p>
            🐵 If you enjoyed this app, help me keep it alive by signing up for
            my courses where I teach you the tech to build such cool apps.
          </p>
          <p className="mt-2">❤️ My mom says she loved all my courses.</p>
          <a
            className="gumroad-button mt-4"
            target="_blank"
            href="https://ngninja.gumroad.com/"
          >
            🔗 Check out my mom's recommendations!
          </a>
        </div>
      </div>
    </div>
  );
};

export default Apps;
