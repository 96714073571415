import "./Ground.css";
import React, { useState, useEffect } from "react";
import Box from "./comp/Box";
import BoxGroup from "./comp/BoxGroup";
import imgData from "./comp/ImgData";
import { Link } from "gatsby";

// constants
let FEMALE_START_POS = {
  x: 10,
  y: 10,
  img: imgData.female,
};

let FLOWER_START_POS = {
  x: 10,
  y: 30,
  img: imgData.f2,
  trans: "all 0.3s linear",
  class: "",
};

let WINER_POS = {
  x: 50,
  y: 20,
  img: imgData.f2,
};

let START_POS_ARR = [
  {
    x: 90,
    y: 10,
    bg: "blue",
    id: "1",
    img: imgData.m1,
  },
  {
    x: 90,
    y: 25,
    bg: "green",
    id: "2",
    img: imgData.m2,
  },
  {
    x: 90,
    y: 40,
    bg: "yellow",
    id: "3",
    img: imgData.m3,
  },
  {
    x: 90,
    y: 55,
    bg: "purple",
    id: "4",
    img: imgData.m4,
  },
  {
    x: 90,
    y: 70,
    bg: "purple",
    id: "6",
    img: imgData.m6,
  },
  {
    x: 90,
    y: 85,
    bg: "purple",
    id: "8",
    img: imgData.m8,
  },
];

const Ground = () => {
  // ############# state
  // boxState
  const [boxPos, setBoxPos] = useState(FLOWER_START_POS);
  const [posArr, setPosArr] = useState(START_POS_ARR);
  const [winningPos, setWinningPos] = useState(WINER_POS);
  // over
  const [isOver, setIsOver] = useState(false);
  // move flower
  const [isFlowerMove, setIsFlowerMove] = useState(false);

  // func
  const moveBox = (ev) => {
    // return if game over
    if (isOver) {
      reStart();
      return;
    }
    setIsOver(true);
    // let x = ev.clientX - ev.nativeEvent.target.offsetLeft;
    // let y = ev.clientY - ev.nativeEvent.target.offsetLeft;
    // console.log("Pos :", { x, y });
    let randomPosObj = posArr[randomNum(posArr.length)];
    setBoxPos({ ...randomPosObj, img: imgData.f2 });

    // reset post
    resetAfterSecond(randomPosObj);
  };

  const randomNum = (len) => {
    return Math.floor(Math.random() * len);
  };

  const resetAfterSecond = (randomPosObj) => {
    setTimeout(() => {
      setBoxPos(winningPos);
      selectedAtStart(randomPosObj);
    }, 1000);
  };

  const selectedAtStart = (randomPosObj) => {
    setPosArr((prev) => {
      let newPrev = posArr.map((item) => {
        if (item.id === randomPosObj.id) {
          return {
            x: winningPos.x + 2,
            y: winningPos.y,
            id: item.id,
            bg: item.bg,
            img: item.img,
          };
        }
        return item;
      });
      return newPrev;
    });
  };

  const reStart = () => {
    setIsOver(false);
    setPosArr(START_POS_ARR);
    setBoxPos(FLOWER_START_POS);
  };

  const moveFlower = (ev) => {
    if (!isFlowerMove) return;
    let x = ev.clientX - 90;
    let y = ev.clientY - 60;
    console.log({ x, y });
    // console.log(ev.nativeEvent.target.offsetLeft-20);
    // console.log(ev.nativeEvent.target.offsetTop-20);
    setBoxPos({ ...FLOWER_START_POS, x, y, trans: "all 0s linear" });
  };

  const stopFlower = (ev) => {
    setIsFlowerMove(false);
    moveBox();
  };

  const startFlower = (ev) => {
    setIsFlowerMove(true);
    setBoxPos((prev) => ({ ...prev, class: "activeFlower" }));
  };

  // ###########effec
  // set pos to zero
  // useEffect(() => {

  // }, [boxPos])

  return (
    <div className="ground">
      {/* <div className="toolBox">
                <button className='btn btn-primary' onClick={reStart}>Retery</button>
            </div> */}
      <div className="appTitle">
        <h1>Darlings: Marry Alia Bhatt</h1>
        <div className="appSubtitle">
          <span>- by</span>
          <a
            href="https://twitter.com/sleeplessyogi"
            target="_blank"
            title="Twitter: Sleepless Yogi"
          >
            <p>@sleeplessyogi</p>
          </a>
        </div>
      </div>
      <div
        className="groundBox"
        onMouseMove={moveFlower}
        onMouseUp={() => setIsFlowerMove(false)}
      >
        {/* <button className='throwBtn' onClick={moveBox} title='Throw and Retery Button'>
                    <img src={imgData.throwIcon} alt="" />
                </button> */}

        <Box boxPos={{ ...FEMALE_START_POS }} />

        {/* <Box boxPos={boxPos}  /> */}

        <div
          onDragStart={(ev) => ev.preventDefault()}
          className="box"
          style={{
            animation: isFlowerMove
              ? `moveCircle 0.3s linear infinite`
              : "none",
            transition: boxPos.trans,
            left: `${boxPos.x}%`,
            top: `${boxPos.y}%`,
          }}
          onMouseUp={stopFlower}
          onMouseDown={startFlower}
        >
          <img
            onDragStart={(ev) => ev.preventDefault()}
            src={boxPos.img}
            className={
              isFlowerMove ? `noActiveFlower boxImg` : `activeFlower boxImg`
            }
            alt=""
          />
        </div>

        <BoxGroup posArr={posArr} />
      </div>

      <div className="infoBox">
        <button className="btn info">
          <img src={imgData.info} alt="" />
          <div className="infoContent">
            <p>Grab flower and throw toward boys</p>
            <p>Click "refresh" to replay</p>
          </div>
        </button>
        <button onClick={reStart} className="btn info">
          <svg
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
          >
            <g>
              <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"></path>
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Ground;
